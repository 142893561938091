.textfield-box {
  &.floating-label {
    @include textfield-box-size($textfield-font-size, $textfield-box-height, $textfield-line-height, $textfield-box-padding-x, $textfield-box-padding-y);

    padding-top: 0;

    &.has-value label,
    &.is-focused label {
      top: $textfield-box-label-spacer-y;
    }

    .form-control {
      padding-bottom: ($textfield-box-label-spacer-y - $textfield-border-width / $font-size-root * 1rem);
    }
  }

  // stylelint-disable-next-line selector-no-qualifying-type
  select.form-control:not([multiple]):not([size]) {
    @include form-select-size($textfield-font-size, $textfield-box-height, $textfield-box-padding-x);
  }

  .form-control {
    @include form-control-size($textfield-font-size, $textfield-box-height, $textfield-line-height, $textfield-box-padding-x, $textfield-box-padding-y);

    background-color: $textfield-box-bg;
    border-radius: $textfield-box-border-radius;
  }
}

// Size
.textfield-box-lg {
  &.floating-label {
    @include textfield-box-size($textfield-font-size-lg, $textfield-box-height-lg, $textfield-line-height-lg, $textfield-box-padding-x-lg, $textfield-box-padding-y-lg);
  }

  // stylelint-disable-next-line selector-no-qualifying-type
  select.form-control:not([multiple]):not([size]) {
    @include form-select-size($textfield-font-size-lg, $textfield-box-height-lg, $textfield-box-padding-x-lg);
  }

  .form-control {
    @include form-control-size($textfield-font-size-lg, $textfield-box-height-lg, $textfield-line-height-lg, $textfield-box-padding-x-lg, $textfield-box-padding-y-lg);
  }
}

.textfield-box-sm {
  &.floating-label {
    @include textfield-box-size($textfield-font-size-sm, $textfield-box-height-sm, $textfield-line-height-sm, $textfield-box-padding-x-sm, $textfield-box-padding-y-sm);
  }

  // stylelint-disable-next-line selector-no-qualifying-type
  select.form-control:not([multiple]):not([size]) {
    @include form-select-size($textfield-font-size-sm, $textfield-box-height-sm, $textfield-box-padding-x-sm);
  }

  .form-control {
    @include form-control-size($textfield-font-size-sm, $textfield-box-height-sm, $textfield-line-height-sm, $textfield-box-padding-x-sm, $textfield-box-padding-y-sm);
  }
}
