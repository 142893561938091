// stylelint-disable selector-no-qualifying-type

.collapse {
  display: none;

  &.show {
    display: block;
  }
}

tbody {
  &.collapse.show {
    display: table-row-group;
  }
}

tr {
  &.collapse.show {
    display: table-row;
  }
}

.collapsing {
  @include transition-standard(height);

  height: 0;
  overflow: hidden;
  position: relative;
}

.fade {
  @include transition-standard(opacity);

  opacity: 0;

  &.show {
    opacity: 1;
  }
}
