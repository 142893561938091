.input-group {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;

  > .custom-file,
  > .custom-select,
  > .form-control {
    flex: 1 1 auto;
    margin-bottom: 0;
    width: 1%;
  }
}

// Addons

.input-group-append,
.input-group-prepend {
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: $textfield-height;

  .btn {
    min-width: 0;

    + .btn {
      margin-left: ($btn-group-inner-spacer-x * -1);
    }
  }
}

.input-group-append {
  margin-left: $input-group-addon-margin-x;
}

.input-group-prepend {
  margin-right: $input-group-addon-margin-x;
}

.input-group-text {
  color: inherit;
  font-size: $textfield-font-size;
  line-height: $textfield-line-height;
  margin-right: ($input-group-addon-margin-x / 2);
  margin-bottom: 0;
  margin-left: ($input-group-addon-margin-x / 2);
  white-space: nowrap;
}

// Border radius

.input-group > .input-group-append > .btn,
.input-group > .input-group-prepend > .btn {
  &:not(:first-of-type) {
    @include border-left-radius(0);
  }

  &:not(:last-of-type) {
    @include border-right-radius(0);
  }
}

// Size

.input-group-lg {
  > .form-control {
    @extend %form-control-lg;
  }

  > .input-group-append,
  > .input-group-prepend {
    min-width: $textfield-height-lg;

    > .btn {
      @extend %btn-lg;
    }

    > .input-group-text {
      font-size: $textfield-font-size-lg;
      line-height: $textfield-line-height-lg;
    }
  }
}

.input-group-sm {
  > .form-control {
    @extend %form-control-sm;
  }

  > .input-group-append,
  > .input-group-prepend {
    min-width: $textfield-height-sm;

    > .btn {
      @extend %btn-sm;
    }

    > .input-group-text {
      font-size: $textfield-font-size-sm;
      line-height: $textfield-line-height-sm;
    }
  }
}
