@mixin make-grid-columns() {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint);

    @for $i from 1 through $grid-columns {
      .col#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }

    @include media-breakpoint-up($breakpoint) {
      @for $i from 1 through $grid-columns {
        .col#{$infix}-#{$i} {
          @include make-col($i);
        }
      }

      @for $i from 0 through ($grid-columns - 1) {
        @if not ($infix == '' and $i == 0) {
          .offset#{$infix}-#{$i} {
            @include make-col-offset($i);
          }
        }
      }

      @for $i from 0 through $grid-columns {
        .order#{$infix}-#{$i} {
          order: $i;
        }
      }

      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      .col#{$infix}-auto {
        flex: 0 0 auto;
        max-width: none;
        width: auto;
      }

      .order#{$infix}-first {
        order: -1;
      }

      .order#{$infix}-last {
        order: $grid-columns + 1;
      }
    }

    .col#{$infix},
    .col#{$infix}-auto {
      @extend %grid-column;
    }
  }

  %grid-column {
    min-height: 1px;
    padding-right: ($grid-gutter-width / 2);
    padding-left: ($grid-gutter-width / 2);
    position: relative;
    width: 100%;
  }
}
