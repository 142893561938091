// N.B. Custom checkbox, radio button and select are defined in Material design
// (https://material.google.com/components/selection-controls.html)
// therefore, these elements are styled in /assets/sass/material/_selection-control.scss

// Custom file

.custom-file {
  display: inline-block;
  height: $textfield-height;
  margin-bottom: 0;
  position: relative;
  width: 100%;
}

.custom-file-input {
  height: $textfield-height;
  margin: 0;
  opacity: 0;
  position: relative;
  width: 100%;
  z-index: 1;

  @include focus-hover {
    ~ .custom-file-label {
      border-bottom-color: $textfield-border-color-focus;
      box-shadow: inset 0 ($textfield-border-width-focus * -1) 0 ($textfield-border-width * -1) $textfield-border-color-focus;
    }
  }
}

.custom-file-label {
  @include form-control-size($textfield-font-size, $textfield-height, $textfield-line-height, $textfield-padding-x, $textfield-padding-y);
  @include text-truncate;
  @include transition-standard(border-color, box-shadow);

  border-bottom: $textfield-border-width solid $textfield-border-color;
  color: $textfield-hint-color;
  height: $textfield-height;
  padding-right: ($material-icon-size + $selection-control-spacer-x);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  @include hover {
    border-bottom-color: $textfield-border-color-hover;
    box-shadow: inset 0 ($textfield-border-width-hover * -1) 0 ($textfield-border-width * -1) $textfield-border-color-hover;
  }

  &::after {
    @include set-material-icons;

    content: $custom-file-button-icon;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
